import React from "react";
import ReactDOM from "react-dom/client";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import App from "./App";
//-------------------------- FIREBASE START --------------------------
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyCXqQ749uEQ7vp08fxPxQwYf5GfEsd_KpY",

  authDomain: "tlbmilsim-com.firebaseapp.com",

  projectId: "tlbmilsim-com",

  storageBucket: "tlbmilsim-com.appspot.com",

  messagingSenderId: "14757438241",

  appId: "1:14757438241:web:a0e8e7edb71be2bdef1460",

  measurementId: "G-7N90S03SKP"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

//-------------------------- FIREBASE END --------------------------
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);